import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import favicon from '../images/favicon.svg'
import '../css/main.css'

// const pageStyles = {
//   color: "#509EFF",
//   padding: "96px",
//   fontFamily: "-apple-system, Roboto, sans-serif, serif",
// }

export default function Template(data) {
  // console.log('data', data)
  const imageFluid = data?.pageContext?.featuredImage?.childImageSharp?.fluid
  return (
    <main className="page blog-page">
      <div className="back-link">
        <Link  to={`/`}>
          <img className="logo" alt="caudurodev Logo" src={favicon} />
          Back
        </Link>
      </div>
      <div>
        <h1>{data.pageContext.title}</h1>
        <h2>{new Date(data.pageContext.date).toLocaleDateString('EN-uk')}</h2>
        {imageFluid && <Img fluid={imageFluid} />}
        <div
          dangerouslySetInnerHTML={{ __html: data.pageContext.html }}
        />
      </div>
    </main>
  )
}